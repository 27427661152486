import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async get() {
    let result = await axios.get(`${API_ROUTES.setting.concepts.get}`);

    return result;
  },

  async update(id, data) {
    let result = await axios.patch(
      `${API_ROUTES.setting.concepts.update}/${id}`,
      data
    );

    return result;
  }
};
